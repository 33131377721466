import api from '@/services/api'

export default {
  getUsers() {
    return api.get('/permissions')
  },

  createUser(data) {
    return api.post('/permissions', data)
  },

  updateUser(data) {
    return api.put(`/permissions/${data._id}`, data)
  },

  removeUser(id) {
    return api.delete(`/permissions/${id}`)
  },
}